import * as React from "react"
import "../styles/index.css"
import { PageProps, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Nav from "../components/Nav/Nav"
import Hero from "../components/Hero/Hero"
import Section from "../components/Section/Section"
import HireSlider from "../components/HireSlider/HireSlider"
import { BsChevronDown } from "react-icons/bs"

function MiniExcavationHire(_props: PageProps): React.ReactNode {
  return (
    <Layout className="bg-black text-white">
      <Seo title="Mini Excavator Hire" />

      <Hero
        id="hire"
        backgroundImage={require("../images/bg/Header-1.jpg").default}
      >
        <Nav />
        <div className="flex flex-col text-center items-center">
          <h2 className="text-4xl md:text-5xl uppercase tracking-widest font-bold mb-8">
            Mini Excavator
          </h2>
          <h3 className="text-2xl md:text-3xl uppercase tracking-widest font-bold mb-16">
            Wet/Dry Hire
          </h3>
          <p className="text-center mb-4 font-bold">
            2017 HITACHI ZX17U-5 (2017)
          </p>
          <p className="text-center mb-4">
            RUBBER TRACK, ZERO SWING EXCAVATOR (1.7t, WIDTH 0.9m -1.2M) <br />
            300mm,450mm,900mm Bucket, Single Ripper Tine, Grader Blade
          </p>
          <p className="text-center font-bold mb-8">
            REGISTERED&nbsp;&nbsp;|&nbsp;&nbsp;INSURED&nbsp;&nbsp;|&nbsp;&nbsp;RISK
            MANAGEMENT SUPPLIED&nbsp;&nbsp;|&nbsp;&nbsp;SITE
            READY&nbsp;&nbsp;|&nbsp;&nbsp;FIRE EXTINGUISHER
          </p>
          <a
            href="tel:0405316336"
            className="px-4 py-2 border border-white transition-colors font-bold hover:bg-white hover:text-black"
          >
            Call now for a free quote
          </a>
        </div>
        <div>
          <BsChevronDown />
        </div>
      </Hero>

      <Section id="slider">
        <HireSlider />
      </Section>
    </Layout>
  )
}

export default MiniExcavationHire
