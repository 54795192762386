import * as React from "react"
import { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import SwiperCore, { Navigation, Autoplay } from "swiper/core"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

// install Swiper modules
SwiperCore.use([Navigation, Autoplay])

function HireSlider() {
  return (
    <div className="flex items-center">
      <div className="hire-slider-prev border border-white p-2 mr-4 transition-colors hover:bg-white hover:text-black">
        <BsChevronLeft />
      </div>
      <Swiper
        className="hire-slider"
        slidesPerView={1}
        navigation={{
          prevEl: ".hire-slider-prev",
          nextEl: ".hire-slider-next",
        }}
        loop
        centeredSlides
        autoHeight
        autoplay
      >
        <SwiperSlide>
          <div className="flex justify-center w-full">
            <img
              src={require("../../images/excavator/for-hire-1.jpg").default}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="flex justify-center w-full">
            <img
              src={require("../../images/excavator/for-hire-2.jpg").default}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="flex justify-center w-full">
            <img
              src={require("../../images/excavator/for-hire-3.jpg").default}
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="hire-slider-next border border-white p-2 ml-4 transition-colors hover:bg-white hover:text-black">
        <BsChevronRight />
      </div>
    </div>
  )
}

export default HireSlider
